import React from "react";
import "./Policies.css"

const Privacy = () => {
  return (
    <div className="policies">
      <h1>Privacy Policy for PEPOLA INDIA PRIVATE LIMITED</h1>
      <span>Last updated: 1st August 2024</span>
      <div className="section">
        <h3>## 1. Introduction</h3>
        <p>
          PEPOLA INDIA PRIVATE LIMITED ("we", "our", or "us"), a company
          registered in India, is committed to protecting the privacy of our
          users ("you" or "your"). This Privacy Policy explains how we collect,
          use, disclose, and safeguard your information when you use our
          website, mobile applications, games, and other services (collectively,
          the "Services").
        </p>
      </div>
      <div className="section">
        <h3>## 2. Information We Collect</h3>
        <section className="sub-section">
          <h4>### 2.1 Personal Information</h4>
          <span>
            We may collect personal information that you provide directly to us,
            including but not limited to:
          </span>
          <ul>
            <li>-Name</li>
            <li>-Email address</li>
            <li>-Phone number</li>
            <li>-Postal address</li>
            <li>-Payment information</li>
            <li>-User account credential</li>
          </ul>
        </section>
        <section className="sub-section">
          <h4>### 2.2 Non-Personal Information</h4>
          <span>
            We may also collect non-personal information automatically when you
            use our Services, including:
          </span>
          <ul>
            <li>- Device information (e.g., device type, operating system)</li>
            <li>- IP address</li>
            <li>- Browser type</li>
            <li>- Usage data and analytics</li>
            <li></li>
          </ul>
        </section>
        <section className="sub-section">
          <h3>### 2.3 Information Collected Through Our Services</h3>
          <span>
            Depending on which of our Services you use, we may collect
            additional information:
          </span>
          <ul>
            <li>- Game data (e.g., player statistics, in-game purchases)</li>
            <li>- 3D models and designs</li>
            <li>- Graphic designs and artwork</li>
            <li>- Website usage data</li>
            <li>- Software usage and performance data</li>
            <li>- AI and machine learning data</li>
          </ul>
        </section>
      </div>
      <div className="section">
        <h3>## 3. How We Use Your Information</h3>
        <span>
          We use the collected information for various purposes, including:
        </span>
        <ul>
          <li>- Providing and maintaining our Services</li>
          <li>- Improving and personalizing user experience</li>
          <li>- Communicating with you about our Services</li>
          <li>- Processing transactions and payments</li>
          <li>- Analyzing usage patterns and trends</li>
          <li>- Developing new products and services</li>
          <li>- Protecting against fraud and unauthorized access</li>
        </ul>
      </div>
      <div className="section">
        <h3>## 4. Information Sharing and Disclosure</h3>
        <span>
          We may share your information in the following circumstances:
        </span>
        <ul>
          <li>
            - With third-party service providers who assist us in operating
            ourServices
          </li>
          <li>- To comply with legal obligations or enforce our policies</li>
          <li>- In connection with a business transfer or acquisition</li>
          <li>- With your consent.</li>
        </ul>
      </div>
      <div className="section">
        <h3>## 5. Data Security</h3>
        <p>
          We implement appropriate technical and organizational measures to
          protect your information. However, no method of transmission over the
          Internet or electronic storage is 100% secure, and we cannot guarantee
          absolute security.
        </p>
      </div>
      <div className="section">
        <h3>## 6. Your Rights</h3>
        <span>
          You have certain rights regarding your personal information,
          including:
        </span>
        <ul>
          <li>- Accessing and reviewing your personal information</li>
          <li>- Requesting corrections to your personal information</li>
          <li>- Requesting deletion of your personal information</li>
          <li>- Opting out of certain data collection and use practices</li>
        </ul>
        <span>
          To exercise these rights, please contact us using the information
          provided in Section 10.
        </span>
      </div>
      <div className="section">
        <h3>## 7. Children's Privacy</h3>
        <p>
          Our Services are not intended for children under the age of 13. We do
          not knowingly collect personal information from children under 13. If
          you are a parent or guardian and believe your child has provided us
          with personal information, please contact us at support@pepola.in.
        </p>
      </div>
      <div className="section">
        <h3>## 8. Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page and
          updating the "Last updated" date.
        </p>
      </div>
      <div className="section">
        <h3>## 9. International Data Transfers</h3>
        <p>
          Your information may be transferred to and processed in countries
          other than your own. By using our Services, you consent to the
          transfer of information to countries that may have different data
          protection rules than your country.
        </p>
      </div>
      <div className="section">
        <h3>## 10. Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at: PEPOLA INDIA PRIVATE LIMITED support@pepola.in
        </p>
      </div>
      <div className="section">
        <h3>## 11. Consent</h3>
        <p>
          By using our Services, you consent to the collection, use, and sharing
          of your information as described in this Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
