import React, { useEffect } from "react";
import "./MainPage.css";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faApple,
  faDiscord,
  faGooglePlay,
} from "@fortawesome/free-brands-svg-icons";

const MainPage = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="mainPage">
      <video className="mainVideo" autoPlay loop muted>
        <source src="/assets/images/Home/BannerMainHome.mov" type="video/mp4" />
      </video>
      <div className="main-aboutUs">
        <h4>
          READ MORE ABOUT US<br />
          <Link to={"AboutUs"}><button>Learn More</button></Link>
        </h4>
      </div>
      <div className="main-banner">
        <div className="banner-game1 banner-img">
          <img src="/assets/images/Home/DeathRealmLogoText.png" alt="" />
          <p>The realm beyond…beckons</p>
          <a
            href="https://store.steampowered.com/app/1671240/Death_Realm/"
            style={{ color: "#fff" }}
            target="_blanck"
          >
            <button>Download Now</button>
          </a>
        </div>
        <div className="banner-game2 banner-img">
          <img src="/assets/images/Home/TheHatchetManLogo.png" alt="" />
          <p>No blood debt left unpaid</p>
          <div className="banner-links">
            <a
              href="https://apps.apple.com/us/app/the-hatchet-man/id6471234616"
              target="_blanck"
            >
              <FontAwesomeIcon
                icon={faApple}
                className="icon-banner"
                style={{ color: "#ffffff" }}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.PEPOLA.TheHatchetMan&hl"
              target="_blanck"
            >
              <FontAwesomeIcon
                icon={faGooglePlay}
                className="icon-banner"
                style={{ color: "#ffffff" }}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="main-discord">
        <FontAwesomeIcon
          icon={faDiscord}
          className="icon"
          style={{ color: "#ffffff" }}
        />
        <h4>Join Our Offical Community Discord!</h4>
        <a href="https://discord.gg/XjskXCJvgm" target="_blanck">
          <button>Join Us</button>
        </a>
      </div>
      <div className="main-ourGames">
        <h4>
          What Make Us Special?
        </h4>
        <Link to={"OurGames"}>
          <button>Read More</button>
        </Link>
      </div>
    </div>
  );
};

export default MainPage;
