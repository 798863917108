import React from "react";
import "./Contact.css";

const Contact = () => {


  return (
    <div className="contactUs">
      <div className="banner banner-co">
        <h2>CONTACT US</h2>
      </div>
      <div className="contact-business">
        <span>
          FOR BUSINESS <br /> (contact@people.in)
        </span>
      </div>
      <div className="contact-form">
        <form>
          <label htmlFor="">Subject</label>
          <input type="text" />
          <div className="form-perosnal">
            <section>
              <label htmlFor="">Full-Name</label>
              <input type="text" />
            </section>
            <section>
              <label htmlFor="">Email</label>
              <input type="email" />
            </section>
          </div>
          <label htmlFor="">Content</label>
          <textarea></textarea>
          <input
            type="submit"
            className="submit"
            style={{ margin: "2rem 0rem",}}
            value="Send"
          />
        </form>
      </div>
    </div>
  );
};

export default Contact;
