import React, { useEffect, useState } from 'react'

const OurGames = () => {
    const [ourGames, setOurGames] = useState([])

    useEffect(() => {
        fetch("/assets/data/ourGames.json")
          .then((response) => response.json())
          .then((data) => setOurGames(data))
          .catch((error) => console.error("Error getting games data:", error));
      }, []);

  return (
    <div className='container'>
        <div className="banner banner-og">
            <h2>OUR GAMES</h2>
        </div>
        <div className="container-data">
            {ourGames.map((g,index) => (
                <div key={g.id} className={`item ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}>
                    <div className="item-image">
                        <img src={g.image} alt="" />
                    </div>
                    <div className="item-data">
                        <p>{g.text}</p>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default OurGames