import React, { useEffect, useState } from "react";
import "./Footer.css";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArtstation, faLinkedin, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const [copyEmail, setCopyEmail] = useState("");
  const location = useLocation()

  const copy = (text) => {
    navigator.clipboard.writeText(text);
    setCopyEmail(text);
    setTimeout(() => {
      setCopyEmail("");
    }, 2000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-emails">
          <h5>CONTACT US</h5>
          <p onClick={() => copy("contact@pepola.in")}>contact@pepola.in</p>
          <p onClick={() => copy("support@pepola.in")}>support@pepola.in</p>
          {copyEmail && <div className="tooltip">Copy!!</div>}
        </div>
        <div className="footer-main">
          <img src="/assets/images/Nav&Footer/PEPOLA.png" alt="" />
          <div className="main-data">
            <h5>FOLLOW US</h5>
            <div className="main-links">
              <a href="https://x.com/PEPOLA_I" target="_blanck"><FontAwesomeIcon icon={faXTwitter} className="nav-icon" style={{color: "#ffffff",}} /></a>
              <a href="https://www.youtube.com/@pepola" target="_blanck"><FontAwesomeIcon icon={faYoutube} className="nav-icon" style={{color: "#ffffff",}} /></a>
              <a href="https://www.artstation.com/pepola_i" target="_blanck"><FontAwesomeIcon icon={faArtstation} className="nav-icon" style={{color: "#ffffff",}} /></a>
              <a href="https://www.linkedin.com/company/pepolapvtltd/" target="_blanck"><FontAwesomeIcon icon={faLinkedin} className="nav-icon" style={{color: "#ffffff",}} /></a>
            </div>
            <p>GoodWorks,Ground Floor,Near 21, 2nd Main Road,Electronic City, Phase-1. Bangalore, Karnataka - 560100</p>
          </div>
        </div>
        <div className="footer-policies">
          {/* cambiarlos por links */}
          <Link to={"Privacy"}><p>PRIVACY POLICY</p></Link>
          <Link to={"Refund"}><p>REFUND POLICY</p></Link>
          <Link to={"Terms"}><p>TERMS & CONDITIONS</p></Link>
        </div>
      </div>
      <span>Copyright 2024 PEPOLA INDIA PRIVATE LIMITED. All rights reserved</span>
    </div>
  );
};

export default Footer;
