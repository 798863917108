import React, { useEffect, useState } from "react";

const OurService = () => {
  const [ourServices, setOutServices] = useState([]);

  useEffect(() => {
    fetch("/assets/data/ourServices.json")
      .then((response) => response.json())
      .then((data) => setOutServices(data))
      .catch((error) => console.error("Error getting News Data:", error));
  }, []);

  const renderSpan = (text) => {
    const parts = text.split(/(\*\*[^*]+\*\*)/g);
    return parts.map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        return <span key={index}>{part.slice(2, -2)}</span>;
      }
      return part;
    });
  };

  return(
    <div className='container'>
    <div className="banner banner-os">
        <h2>OUR SERVICES</h2>
    </div>
    <div className="container-data">
        {ourServices.map((s,index) => (
            <div key={s.id} className={`item ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}>
                    <div className="item-image">
                        <img src={s.image} alt="" />
                    </div>
                <div className="item-data">
                    <p>{renderSpan(s.text)}</p>
                </div>
            </div>
        ))}
    </div>
    </div>
  )
};

export default OurService;
