import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./bars/Footer/Footer";
import NavBar from "./bars/NavBar/NavBar";
import Contact from "./pages/ContactUs/Contact";
import MainPage from "./pages/MainPage/MainPage";
import AboutUs from "./pages/AboutUs/AboutUs";
import OurGames from "./pages/OurGames/OurGames";
import OurService from "./pages/OurServices/OurService";
import Privacy from "./pages/Policies/Privacy";
import Refund from "./pages/Policies/Refund";
import Terms from "./pages/Policies/Terms";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <NavBar />
        <Routes>
          <Route path="/" Component={MainPage} />
          <Route path="AboutUs" Component={AboutUs} />
          <Route path="OurGames" Component={OurGames} />
          <Route path="OurServices" Component={OurService} />
          <Route path="ContactUs" Component={Contact} />
          <Route path="Privacy" Component={Privacy} />
          <Route path="Refund" Component={Refund} />
          <Route path="Terms" Component={Terms} />
          {/* ver que hacer por default */}
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
