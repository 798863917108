import React, { useEffect, useState } from 'react'

const AboutUs = () => {
    const [aboutUs, setAboutUs] = useState([]);

    useEffect(() => {
      fetch("/assets/data/aboutUs.json")
        .then((response) => response.json())
        .then((data) => setAboutUs(data))
        .catch((error) => console.error("Error getting News Data:", error));
    }, []);
  return (
    <div className='container'>
        <div className="banner banner-ab">
            <h2>ABOUT US</h2>
        </div>
        <div className="container-data">
            {aboutUs.map((a,index) => (
                <div key={a.id} className={`item ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}>
                    <div className="item-image">
                        <img src={a.image} alt="" />
                    </div>
                    <div className="item-data">
                        <p>{a.text}</p>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default AboutUs