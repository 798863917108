import React from "react";
import "./Policies.css";

const Refund = () => {
  return (
    <div className="policies">
      <h1>Refund Policy</h1>
      <span>Effective Date: 1st August 2024</span>
      <div className="section">
        <h3>1. Introduction</h3>
        <p>
          Welcome to PEPOLA INDIA PRIVATE LIMITED ("PEPOLA", "we", "us", "our").
          We strive to provide high-quality games, services, and digital
          content. This Refund Policy outlines the terms and conditions under
          which refunds will be processed for our games, services, and other
          products. By using our website or services, you agree to the terms of
          this policy.
        </p>
      </div>
      <div className="section">
        <h3>2. Refunds for Services</h3>
        <span>
          For services provided by PEPOLA, including but not limited to game
          development, 3D design, graphic design, web development, software
          development, and AI development:
        </span>
        <ul>
          <li>
            <span>No Refund Policy:</span>We do not offer refunds for services that have been
            fully delivered and have not faced any issues for a period of 6
            months from the date of completion.
          </li>
          <li>
            <span>Service Issues:</span> If there are any issues with the services provided,
            please contact us within 30 days of the issue being identified. We
            will work to resolve the issue in a timely manner. However, if the
            issue is resolved and no further issues are reported within 6
            months, no refunds will be granted.
          </li>
        </ul>
      </div>
      <div className="section">
        <h3>3. Refunds for Games and Digital Content</h3>
        <span></span>
        <ul>
          <li>
            <span>No Refund Policy:</span> We do not offer refunds for games, in-app
            purchases, DLCs, add-ons, or in-game currency if they have been
            accessed or used for more than 2 hours
          </li>
          <li>
            <span>Exception for Unused Products:</span> If you experience issues with a game
            or digital content within the first 2 hours of use, please contact
            us within 7 days of purchase. We will review the issue and determine
            if a refund or replacement is applicable.
          </li>
          <li>
            <span>Technical Issues:</span> For technical issues related to the games or
            digital content, please report the issue within 7 days of the
            problem occurring. We will make every effort to resolve the issue or
            offer a replacement. However, refunds will not be granted if the
            content has been accessed or used beyond the 2-hour limit.
          </li>
        </ul>
      </div>
      <div className="section">
        <h3>4. How to Request a Refund</h3>
        <span>
          To request a refund, please contact our customer support team via
          [insert contact method] with the following details:
        </span>
        <ul>
          <li>Your order number or transaction ID</li>
          <li>A description of the issue or reason for the refund request</li>
          <li>Any relevant screenshots or documentation</li>
        </ul>
      </div>
      <div className="section">
        <h3>5. Contact Us</h3>
        <span>
          If you have any questions or concerns regarding our Refund Policy,
          please contact us at:
        </span>
        <p>
          PEPOLA INDIA PRIVATE LIMITED <br />
          support@pepola.in
        </p>
      </div>
      <div className="section">
        <h3>6. Changes to the Refund Policy</h3>
        <p>
          We reserve the right to modify or update this Refund Policy at any
          time. Any changes will be effective immediately upon posting on our
          website. We encourage you to review this policy periodically for any
          updates.
        </p>
      </div>
      <div className="section">
        <h3>7. Governing Law</h3>
        <p>
          This Refund Policy is governed by the laws of India. Any disputes
          arising under or in connection with this policy shall be subject to
          the exclusive jurisdiction of the courts located in Bangalore, India.
        </p>
      </div>
    </div>
  );
};

export default Refund;
