import React, { useEffect, useState } from "react";
import "./NavBar.css"
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArtstation, faLinkedin, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";

const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  const btnMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  return (
    <div className="navBar">
      <div className="navBar-logo">
        <Link to={"/"}><img src="/assets/images/Nav&Footer/PEPOLA.png" alt="peopla logo" /></Link>
      </div>
      <div className={`nav-list ${showMenu ? 'show' : ''}`}>
        <FontAwesomeIcon icon={faX} style={{ color: "#ffffff" }} className="icon-x" onClick={closeMenu} />
        <ul>
        <Link to={"/"} onClick={closeMenu}><li className="list-item">Home</li></Link>
          <Link to={"AboutUs"} onClick={closeMenu}><li className="list-item">About</li></Link>
          <Link to={"OurGames"} onClick={closeMenu}><li className="list-item">Games</li></Link>
          <Link to={"OurServices"} onClick={closeMenu}><li className="list-item">Services</li></Link>
          <Link to={"ContactUs"} onClick={closeMenu}><li className="list-item">Contact</li></Link>
        </ul>
        <div className="navBar-links">
          <a href="https://x.com/PEPOLA_I" target="_blanck"><FontAwesomeIcon icon={faXTwitter} className="nav-icon" style={{color: "#ffffff",}} /></a>
          <a href="https://www.youtube.com/@pepola" target="_blanck"><FontAwesomeIcon icon={faYoutube} className="nav-icon" style={{color: "#ffffff",}} /></a>
          <a href="https://www.artstation.com/pepola_i" target="_blanck"><FontAwesomeIcon icon={faArtstation} className="nav-icon" style={{color: "#ffffff",}} /></a>
          <a href="https://www.linkedin.com/company/pepolapvtltd/" target="_blanck"><FontAwesomeIcon icon={faLinkedin} className="nav-icon" style={{color: "#ffffff",}} /></a>
        </div>
      </div>
      <FontAwesomeIcon icon={faBars} style={{ color: "#ffffff" }} className="icon-bar" onClick={btnMenu} />
    </div>
  );
};


export default NavBar;

{/* <a href="https://x.com/PEPOLA_I" target="_blanck"><img src="/assets/images/Nav&Footer/X.png" className="nav-icon" alt="" /></a>
<a href="https://www.youtube.com/@pepola" target="_blanck"><img src="/assets/images/Nav&Footer/Youtube.png" className="nav-icon" alt="" /></a>
<a href="https://www.artstation.com/pepola_i" target="_blanck"><img src="/assets/images/Nav&Footer/artstation.png" className="nav-icon" alt="" /></a>
<a href="https://www.linkedin.com/company/pepolapvtltd/" target="_blanck"><img src="/assets/images/Nav&Footer/LinkedIn.png" className="nav-icon" alt="" /></a> */}