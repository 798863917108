import React from "react";
import "./Policies.css"

const Terms = () => {
  return (
    <div className="policies">
      <h1>Terms and Conditions for PEPOLA INDIA PRIVATE LIMITED</h1>
      <span>Last Updated: 1st August 2024</span>
      <div className="section">
        <h3>## 1. Introduction</h3>
        <p>
          Welcome to PEPOLA INDIA PRIVATE LIMITED. These Terms and Conditions
          ("Terms") govern your use of our website, mobile applications, games,
          and other services (collectively, the "Services") provided by PEPOLA
          INDIA PRIVATE LIMITED ("we", "our", or "us"), a company registered in
          India. By accessing or using our Services, you agree to be bound by
          these Terms.
        </p>
      </div>
      <div className="section">
        <h3>## 2. Services</h3>
        <span>We offer a range of services including, but not limited to:</span>
        <ul>
          <li>- Game Development</li>
          <li>- 3D Design</li>
          <li>- Graphic Design</li>
          <li>- Web Development</li>
          <li>- Software Development</li>
          <li>- AI Development</li>
          <li>- VTuber Model & Asset Creation</li>
          <li>- VRChat Avatar Services</li>
        </ul>
      </div>
      <div className="section">
        <h3>## 3. User Accounts</h3>
        <p>
          3.1. Some of our Services may require you to create an account. You
          are responsible for maintaining the confidentiality of your account
          information and for all activities that occur under your account.
        </p>
        <p>
          3.2. You agree to provide accurate, current, and complete information
          during the registration process and to update such information to keep
          it accurate, current, and complete.
        </p>
      </div>
      <div className="section">
        <h3>## 4. User Conduct</h3>
        <p>
          4.1. Use the Services for any illegal purpose or in violation of any
          local, state, national, or international law.
        </p>
        <p>
          4.2. Violate or encourage others to violate the rights of third
          parties, including intellectual property rights.
        </p>
        <p>
          4.3. Transmit any viruses, malware, or other types of malicious
          software.
        </p>
        <p>
          4.4. Attempt to gain unauthorized access to our Services, other user
          accounts, or computer systems or networks connected to our Services.
        </p>
        <p>
          4.5. Use our Services in any manner that could interfere with,
          disrupt, negatively affect, or inhibit other users from fully enjoying
          our Services.
        </p>
      </div>
      <div className="section">
        <h3>## 5. Intellectual Property</h3>
        <p>
          5.1. Our Services and their entire contents, features, and
          functionality (including but not limited to all information, software,
          text, displays, images, video, and audio, and the design, selection,
          and arrangement thereof) are owned by PEPOLA INDIA PRIVATE LIMITED,
          its licensors, or other providers of such material and are protected
          by Indian and international copyright, trademark, patent, trade
          secret, and other intellectual property or proprietary rights laws.
        </p>
        <p>
          5.2. You may not reproduce, distribute, modify, create derivative
          works of, publicly display, publicly perform, republish, download,
          store, or transmit any of the material on our Services, except as
          incidental to normal web browsing or as expressly permitted in writing
          by us.
        </p>
      </div>
      <div className="section">
        <h3>## 6. User Content</h3>
        <p>
          6.1. Our Services may allow you to submit, upload, publish or
          otherwise make available content, including but not limited to text,
          photographs, videos, or audio clips. You retain any copyright that you
          may have in your content.
        </p>
        <p>
          6.2. By making any content available through our Services, you grant
          to PEPOLA INDIA PRIVATE LIMITED a worldwide, non-exclusive,
          transferable, sublicensable, royalty-free license to use, copy,
          modify, create derivative works based upon, distribute, publicly
          display, and publicly perform your content in connection with
          operating and providing our Services.
        </p>
      </div>
      <div className="section">
        <h3>## 7. Payments and Billing</h3>
        <p>
          7.1. Certain Services may require payment. By using such Services, you
          agree to pay all fees and charges associated with your account on a
          timely basis and according to the fees, charges, and billing terms in
          effect at the time.
        </p>
        <p>
          7.2. You agree to provide current, complete, and accurate purchase and
          account information for all purchases made via our Services.
        </p>
      </div>
      <div className="section">
        <h3>## 8. Disclaimers</h3>
        <p>
          8.1. THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS,
          WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
        </p>
        <p>
          8.2. PEPOLA INDIA PRIVATE LIMITED DISCLAIMS ALL WARRANTIES, EXPRESS OR
          IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT.
        </p>
      </div>
      <div className="section">
        <h3>## 9. Limitation of Liability</h3>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
          PEPOLA INDIA PRIVATE LIMITED BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
          SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR
          REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF
          DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES.
        </p>
      </div>
      <div className="section">
        <h3>## 10. Indemnification</h3>
        <p>
          You agree to indemnify, defend, and hold harmless PEPOLA INDIA PRIVATE
          LIMITED and its officers, directors, employees, agents, affiliates,
          successors, and assigns from and against any and all losses, damages,
          liabilities, deficiencies, claims, actions, judgments, settlements,
          interest, awards, penalties, fines, costs, or expenses of whatever
          kind, including reasonable attorneys' fees, arising from or relating
          to your use or misuse of our Services or your breach of these Terms.
        </p>
      </div>
      <div className="section">
        <h3>## 11. Governing Law and Jurisdiction</h3>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of India. Any legal suit, action, or proceeding arising out of or
          related to these Terms or the Services shall be instituted exclusively
          in the courts of [City], India.
        </p>
      </div>
      <div className="section">
        <h3>## 12. Changes to Terms</h3>
        <p>
          We reserve the right to modify these Terms at any time. If we make
          changes to these Terms, we will provide notice of such changes, such
          as by sending an email notification, providing notice through the
          Services, or updating the "Last Updated" date at the beginning of
          these Terms.
        </p>
      </div>
      <div className="section">
        <h3>## 13. Contact Information</h3>
        <p>
          If you have any questions about these Terms, please contact us at:
        </p>
        <span>
          PEPOLA INDIA PRIVATE LIMITED <br /> support@pepola.in
        </span>
        <span>
          By using our Services, you acknowledge that you have read and
          understood these Terms and agree to be bound by them.
        </span>
      </div>
    </div>
  );
};

export default Terms;
